const pending = 'pending';
const expired = 'expired';
const accepted = 'accepted';
const canceled = 'canceled';

export const STATUS_NAMES = {
	pending,
	expired,
	accepted,
	canceled,
};

export const TREE_LEG = ['left', 'right'];
